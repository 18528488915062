.dn-resource {
  flex-wrap: wrap;
  overflow: hidden;
}
.dn-resource-header {
  display: flex;
  align-items: center;
  padding: 5px 8px;
  color: var(--dn-collapse-header-color);
  border-bottom: 1px solid var(--dn-panel-border-color);
  background-color: var(--dn-panel-active-bg-color);
  cursor: pointer;
  transition: all 0.25s ease-in-out;
  font-size: 13px;
}
.dn-resource-header-expand {
  transform: rotate(-90deg);
  font-size: 12px;
  transition: all 0.15s ease-in-out;
  margin-right: 3px;
}
.dn-resource-content-wrapper {
  display: flex;
  justify-content: center;
  background: var(--dn-resource-content-bg-color);
}
.dn-resource-content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  display: none;
}
.dn-resource.expand .dn-resource-content {
  display: grid;
  grid-template-columns: repeat(3, 33.3333%);
  grid-gap: 1px;
  background-color: var(--dn-panel-border-color);
  border-bottom: 1px solid var(--dn-panel-border-color);
}
.dn-resource.expand .dn-resource-header-expand {
  transform: rotate(0);
}
.dn-resource-item {
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background: var(--dn-resource-content-bg-color);
  min-height: 40px;
  color: var(--dn-resource-item-color);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: -webkit-grab;
  cursor: grab;
  transition: color 0.1s ease-out;
}
.dn-resource-item:hover {
  color: var(--dn-resource-item-hover-color);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1;
}
.dn-resource-item-icon {
  margin: 12px 0;
}
.dn-resource-item-text {
  text-align: center;
  font-size: 12px;
  line-height: 1;
  margin-bottom: 12px;
}
.dn-resource-item-remain {
  background: var(--dn-resource-content-bg-color);
}
