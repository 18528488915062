/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.dn-designable-form .ant-input,
.dn-designable-form .ant-input-number,
.dn-designable-form .ant-input-affix-wrapper,
.dn-designable-form .ant-cascader-picker,
.dn-designable-form .ant-picker-input,
.dn-designable-form .ant-picker,
.dn-designable-form .ant-cascader-picker-label,
.dn-designable-form .ant-slider,
.dn-designable-form .ant-checkbox,
.dn-designable-form .ant-rate,
.dn-designable-form .ant-switch,
.dn-designable-form .ant-radio,
.dn-designable-form .ant-radio-wrapper,
.dn-designable-form .ant-checkbox-group,
.dn-designable-form .ant-checkbox-wrapper,
.dn-designable-form .ant-radio-group,
.dn-designable-form .ant-upload,
.dn-designable-form .ant-transfer,
.dn-designable-form .ant-select,
.dn-designable-form .ant-select-selector {
  pointer-events: none !important;
}
.dn-designable-form .ant-input input,
.dn-designable-form .ant-input-number input,
.dn-designable-form .ant-input-affix-wrapper input,
.dn-designable-form .ant-cascader-picker input,
.dn-designable-form .ant-picker-input input,
.dn-designable-form .ant-picker input,
.dn-designable-form .ant-cascader-picker-label input,
.dn-designable-form .ant-slider input,
.dn-designable-form .ant-checkbox input,
.dn-designable-form .ant-rate input,
.dn-designable-form .ant-switch input,
.dn-designable-form .ant-radio input,
.dn-designable-form .ant-radio-wrapper input,
.dn-designable-form .ant-checkbox-group input,
.dn-designable-form .ant-checkbox-wrapper input,
.dn-designable-form .ant-radio-group input,
.dn-designable-form .ant-upload input,
.dn-designable-form .ant-transfer input,
.dn-designable-form .ant-select input,
.dn-designable-form .ant-select-selector input {
  pointer-events: none !important;
}
.dn-designable-form .anticon svg {
  pointer-events: none;
}
