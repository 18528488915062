@-webkit-keyframes dn-animate-slide-to-top {
  from {
    transform: translateY(-10%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 0.8;
  }
}
@keyframes dn-animate-slide-to-top {
  from {
    transform: translateY(-10%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 0.8;
  }
}
.dn-auxtool {
  transform: perspective(1px) translate3d(0, 0, 0);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 9998;
}
.dn-aux-button button {
  font-size: 12px !important;
  display: flex;
  align-items: center;
  padding: 0 3px;
  height: 20px;
  color: var(--dn-aux-selector-btn-color);
  background: var(--dn-aux-selector-btn-bg-color);
  border-color: var(--dn-aux-selector-btn-border-color);
}
.dn-aux-button button:hover,
.dn-aux-button button:focus {
  color: var(--dn-aux-selector-btn-hover-color);
  background: var(--dn-aux-selector-btn-hover-bg-color);
  border-color: var(--dn-aux-selector-btn-hover-border-color);
}
.dn-aux-button button:active {
  color: var(--dn-aux-selector-btn-active-color);
  background: var(--dn-aux-selector-btn-active-bg-color);
  border-color: var(--dn-aux-selector-btn-active-border-color);
}
.dn-aux-cover-rect.dragging {
  background-color: var(--dn-aux-cover-rect-dragging-color);
}
.dn-aux-cover-rect.dropping {
  background-color: var(--dn-aux-cover-rect-dropping-color);
}
.dn-aux-free-selection {
  background-color: var(--dn-aux-free-selection-background-color);
  border-color: var(--dn-aux-free-selection-border-color);
}
.dn-aux-helpers {
  position: absolute;
  pointer-events: all;
  z-index: 10;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.dn-aux-helpers button {
  font-size: 12px !important;
  display: flex;
  align-items: center;
  padding: 0 3px;
  height: 20px;
  color: var(--dn-aux-selector-btn-color);
  background: var(--dn-aux-selector-btn-bg-color);
  border-color: var(--dn-aux-selector-btn-border-color);
}
.dn-aux-helpers button:hover,
.dn-aux-helpers button:focus {
  color: var(--dn-aux-selector-btn-hover-color);
  background: var(--dn-aux-selector-btn-hover-bg-color);
  border-color: var(--dn-aux-selector-btn-hover-border-color);
}
.dn-aux-helpers button:active {
  color: var(--dn-aux-selector-btn-active-color);
  background: var(--dn-aux-selector-btn-active-bg-color);
  border-color: var(--dn-aux-selector-btn-active-border-color);
}
.dn-aux-helpers.bottom-right {
  top: 100%;
  right: 0;
}
.dn-aux-helpers.bottom-left {
  top: 100%;
  left: 0;
}
.dn-aux-helpers.bottom-center {
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}
.dn-aux-helpers.inner-top-right {
  top: -2px;
  right: 2px;
}
.dn-aux-helpers.inner-top-left {
  top: -2px;
  left: 2px;
}
.dn-aux-helpers.inner-top-center {
  top: -2px;
  right: 2px;
}
.dn-aux-helpers.inner-bottom-right {
  bottom: -2px;
  right: 2px;
}
.dn-aux-helpers.inner-bottom-left {
  bottom: -2px;
  left: 2px;
}
.dn-aux-helpers.inner-bottom-center {
  bottom: -2px;
  right: 2px;
}
.dn-aux-helpers.top-right {
  bottom: 100%;
  right: 0;
}
.dn-aux-helpers.top-left {
  bottom: 100%;
  left: 0;
}
.dn-aux-helpers.top-center {
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
}
.dn-aux-helpers button span {
  transform: scale(0.9);
  margin-left: 2px;
}
.dn-aux-helpers button span.dn-icon {
  transform: scale(1);
  margin-left: 0;
}
.dn-aux-helpers-content {
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
}
.dn-aux-helpers-content button {
  font-size: 12px !important;
  display: flex;
  align-items: center;
  padding: 0 3px;
  height: 20px;
}
.dn-aux-helpers-content > * {
  margin-top: 4px;
  margin-bottom: 4px;
  margin-left: 2px;
}
.dn-aux-helpers-content > *:first-child {
  margin-left: 0;
}
.dn-aux-insertion {
  background-color: var(--dn-aux-insertion-color);
}
.dn-aux-dashed-box {
  border: 1px dashed var(--dn-aux-dashed-box-color);
}
.dn-aux-dashed-box-title {
  color: var(--dn-aux-dashed-box-title-color);
}
.dn-aux-selection-box {
  border: 2px solid var(--dn-aux-selection-box-border-color);
  position: relative;
  pointer-events: none;
}
.dn-aux-selection-box-inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.dn-aux-selection-box-title {
  color: var(--dn-aux-selection-box-color);
}
.dn-aux-selector button {
  font-size: 12px !important;
  display: flex;
  align-items: center;
  padding: 0 3px;
  height: 20px;
  color: var(--dn-aux-selector-btn-color);
  background: var(--dn-aux-selector-btn-bg-color);
  border-color: var(--dn-aux-selector-btn-border-color);
}
.dn-aux-selector button:hover,
.dn-aux-selector button:focus {
  color: var(--dn-aux-selector-btn-hover-color);
  background: var(--dn-aux-selector-btn-hover-bg-color);
  border-color: var(--dn-aux-selector-btn-hover-border-color);
}
.dn-aux-selector button:active {
  color: var(--dn-aux-selector-btn-active-color);
  background: var(--dn-aux-selector-btn-active-bg-color);
  border-color: var(--dn-aux-selector-btn-active-border-color);
}
.dn-aux-selector-menu {
  margin-top: -4px;
  -webkit-animation: dn-animate-slide-to-top 0.2s;
          animation: dn-animate-slide-to-top 0.2s;
  opacity: 0.8;
}
.dn-aux-selector-menu button {
  font-size: 12px !important;
  display: flex;
  align-items: center;
  padding: 0 3px;
  height: 20px;
  margin-top: 2px;
}
.dn-aux-drag-handler {
  cursor: move;
}
.dn-aux-node-resize-handler {
  position: absolute;
  width: 10px;
  height: 10px;
  pointer-events: all;
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid var(--dn-brand-color);
}
.dn-aux-node-resize-handler.left-center {
  left: 0;
  top: 50%;
  transform: translate(calc(-50% - 1px), -50%);
  cursor: ew-resize;
}
.dn-aux-node-resize-handler.right-center {
  left: 100%;
  top: 50%;
  transform: translate(calc(-50% + 1px), -50%);
  cursor: ew-resize;
}
.dn-aux-node-resize-handler.center-top {
  left: 50%;
  top: 0;
  transform: translate(-50%, calc(-50% - 1px));
  cursor: ns-resize;
}
.dn-aux-node-resize-handler.center-bottom {
  left: 50%;
  top: 100%;
  transform: translate(-50%, calc(-50% + 1px));
  cursor: ns-resize;
}
.dn-aux-node-resize-handler.left-top {
  left: 0;
  top: 0;
  transform: translate(calc(-50% + 1px), calc(-50% + 1px));
  cursor: nwse-resize;
}
.dn-aux-node-resize-handler.left-bottom {
  left: 0;
  top: 100%;
  transform: translate(-50%, -50%);
  cursor: nesw-resize;
}
.dn-aux-node-resize-handler.right-bottom {
  left: 100%;
  top: 100%;
  transform: translate(-50%, -50%);
  cursor: nwse-resize;
}
.dn-aux-node-resize-handler.right-top {
  left: 100%;
  top: 0;
  transform: translate(-50%, -50%);
  cursor: nesw-resize;
}
.dn-aux-node-translate-handler {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  width: 40px;
  height: 20px;
  background: #1890ff;
  opacity: 0.5;
  pointer-events: all;
}
.dn-aux-space-block-ruler-indicator {
  position: absolute;
  background-color: var(--dn-brand-color);
  color: var(--dn-white);
  border-radius: 8px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dn-aux-space-block-ruler-indicator span {
  margin: 0 6px;
  display: inline-block;
  font-size: 12px;
}
.dn-aux-space-block-ruler-h {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  width: 100%;
  height: 12px;
  border-left: 1px solid var(--dn-brand-color);
  border-right: 1px solid var(--dn-brand-color);
}
.dn-aux-space-block-ruler-h::after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translate(0, -50%);
  width: 100%;
  height: 1px;
  background-color: var(--dn-brand-color);
  z-index: 1;
}
.dn-aux-space-block-ruler-h .dn-aux-space-block-ruler-indicator {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0.7);
}
.dn-aux-space-block-ruler-v {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  height: 100%;
  width: 12px;
  border-top: 1px solid var(--dn-brand-color);
  border-bottom: 1px solid var(--dn-brand-color);
}
.dn-aux-space-block-ruler-v::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  height: 100%;
  width: 1px;
  background-color: var(--dn-brand-color);
  z-index: 1;
}
.dn-aux-space-block-ruler-v .dn-aux-space-block-ruler-indicator {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0.7);
}
